import React from "react"
import Header from "../../layouts/header"
import Canvas from "../../layouts/canvas"
// import StartPage from "../../layouts/startpagecontent"
import Footer from "../../layouts/footer"
import Breadcrumb from "../../layouts/breadcrumb"
import { navigate, graphql } from "gatsby"
import { CircleSpinner } from "react-spinners-kit"
import { Helmet } from "react-helmet"
import {Link} from 'gatsby'

class WorksPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      nameerror: "",
      email: "",

      message: "",
      messageerror: "",
      emailvalid: false,
      emailerror: "",
      buttondisabled: true,
      success: false,
      loading: false,
      errorsend: "",
    }
  }

  handleChange = e => {
    var $ = window.$
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    if (e.target.name !== "email" && e.target.value) {
      $(`.${e.target.name}`).removeClass("notvalid")
      this.setState({
        [`${e.target.name}error`]: "",
      })
    }
    if (e.target.name === "email" && e.target.value.match(pattern)) {
      $(`.${e.target.name}`).removeClass("notvalid")
      this.setState({
        [`${e.target.name}error`]: "",
      })
    }

    this.setState({
      [e.target.name]: e.target.value,
      buttondisabled: true,
    })

    setTimeout(() => {
      if (
        this.state.name &&
        this.state.email.match(pattern) &&
        this.state.message
      ) {
        this.setState({
          buttondisabled: false,
        })
      }
    }, 500)
  }

  handleBlur = e => {
    var $ = window.$
    if (e.target.name === "email") {
      var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/

      if (!e.target.value.match(pattern)) {
        $(".email").addClass("notvalid")
        this.setState({
          emailerror: "email is required",
        })
      } else {
        $(".email").removeClass("notvalid")
        this.setState({
          emailvalid: true,
          emailerror: "",
        })
      }
    } else {
      if (!e.target.value) {
        $(`.${e.target.name}`).addClass("notvalid")
        this.setState({
          [`${e.target.name}error`]: `${e.target.placeholder} is required`,
        })
      } else {
        $(`.${e.target.name}`).removeClass("notvalid")
        this.setState({
          [`${e.target.name}error`]: "",
        })
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ loading: true })
    console.log("Submiting")
    var baseurl = this.props.data.allSitePlugin.edges[0].node.pluginOptions.url
    var data = {
      webform_id: "contact",

      webform_data: {
        name: this.state.name,

        email: this.state.email,

        message: this.state.message,
      },
    }
    var url = `${baseurl}/wd_webform/submit`
    fetch(url, {
      method: "post",
      mode: "cors",
      // headers: {
      //      'Content-Type': 'application/json',
      //      // 'Content-Type': 'application/x-www-form-urlencoded',
      //  },
      body: JSON.stringify(data),
    })
      .then(function(response) {
        return response.json()
      })
      .then(res => {
        console.log("message sending in fetch")
        window.gtag("event", "contact_form_submit")
        this.setState({
          name: "",
          nameerror: "",
          email: "",

          message: "",
          messageerror: "",
          emailvalid: false,
          emailerror: "",
          buttondisabled: true,
          success: true,
          loading: false,
        })
        setTimeout(() => {
          navigate("/")
        }, 1500)
      })
      .catch(error => {
        window.gtag("event", "contact_form_fail")

        this.setState({
          loading: false,
          errorsend: "Network Error",
        })
        setTimeout(() => {
          navigate("/")
        }, 1500)
      })
  }

  componentWillUnmount() {
    console.log("WorksPage Unmounting")
    // document.getElementById("mainscript").remove();
  }

  componentDidUpdate() {}
  render() {
    var loc = [] //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item, i) => {
      var value = null
      var path = null
      // console.log(item,i,loc[0])

      if (i === 0) {
        if (item === "en" || item === "el") {
          value = "HOME"
          path = `/${item}`
        } else {
          value = item
          path = `/${item}`
        }
        loc.push({ value: value, path: path })
      } else {
        value = item
        path = `${loc[i - 1].path}/${item}`
        loc.push({ value: value, path: path })
      }
    })

    return (
      <Canvas>
        <Helmet>
          {this.props.data.drupal.metainfo.entities[0].entityMetatags.map(
            (item, i) => {
              if (item.key === "title") {
                return [
                  <title key={i}>{`Our Works | WebDimension`}</title>,
                  <meta property="og:title" content={`Our Works | WebDimension`} />,
                ]
              } else if (item.key === "description") {
                return [
                  <meta key={i} name="description" content={item.value} />,
                  <meta property="og:description" content={item.value} />,
                ]
              } else {
                return null
              }
            }
          )}
          <link
            rel="canonical"
            href={process.env.FRONT_URL + this.props.path}
          />
          <meta property="og:type" content="Website" />
          <meta
            property="og:url"
            content={`${process.env.FRONT_URL}${this.props.path}`}
          />
          <meta
            property="og:image"
            content={`${process.env.FRONT_URL}/wd_logo.png`}
          />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />
        </Helmet>

        <Header
          menu={this.props.data.drupal.menuen}
          pathname="/works"
          language="EN"
          langpath={[
            { url: "/en/works", id: "en" },
            { url: "/el/works", id: "el" },
          ]}
        />
        <Breadcrumb title="Our Works" path={loc} />

            {/***  lavie ***/}
            <section className={`row py-5 position-relative my-0 sectionContainer`}>

                <div className='d-none d-lg-block'>
                    {/* background image */}
                    <img src="/new/works_page/lavie/background-lavie.jpg"
                        alt="lavie bg"
                        style={{
                            objectFit: "cover",
                            height: '70%',
                            position: 'absolute',
                            width: '100%',
                            left: '0',
                            top: '15%',
                            right: '0',
                            color: 'transparent',
                        }}
                        />
                </div>


                <div className='d-block d-lg-none'>
                   
                    <img 
                         src="/new/works_page/lavie/lavie-background-mobile.jpg"
                         alt="lavie bg"
                         style={{
                            objectFit: "cover",
                            objectPosition: 'top left',
                            position: 'absolute',
                            width: '100%',
                            left: '0',
                            top: '150px',
                            right: '0',
                            bottom: '0',
                            color: 'transparent',
                        }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1  d-flex justify-content-center align-items-center'>

                    <div className="position_relative">

                        <div className={`bgText mobileExtraBottomPadding`}>
                            <img src='/new/works_page/lavie/lavie-logo.png' alt='lavie logo' className="img-fluid"/>

                            <div>
                                <p className={`magentoShop`}>Magento Eshop</p>

                                <p className={`paragraph`}>
                                  The rapid growth of the business "La vie en Rose by Dimitra Katsafadou" in the digital space presented a challenge in managing the high volume of online visitors. To address this issue, we decided to implement a combined technological approach.
                                </p>

                                <p className={`paragraph`}>
                                We utilized React for the front end, while Magento supports the e-shop's backend, offering all the essential functionalities for a comprehensive online shopping experience. Finally, to ensure smooth and quick page loading, as well as the efficient real-time data management, we incorporated Redis into our architecture.
                                </p>

                                <p className={`paragraph`}>
                                Through this technological approach, we managed to handle the substantial traffic, guaranteeing the platform's responsiveness and performance under all conditions.
                                </p>

                                <button data-btn={'lavie'} className={`btn`}>
                                  <Link to={'/en/node/120'}>
                                    See the case study
                                  </Link>
                                  </button>
                            </div>

                            <div className={`lavieItemDesktop`}>
                                <img src='/new/works_page/lavie/lavie_item.png'
                                    alt='lavie item'
                                    width='256px'
                                    height='292px'
                                />
                            </div>

                            <div className={`lavieItemMobile`}>
                                <img src='/new/works_page/lavie/lavie_item.png'
                                    alt='lavie item'
                                    width='128px'
                                    height='146px'
                                />
                            </div>



                        </div>
                    </div>

                </div>


                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className={`lavieDesktopImage`}>

                            <img 
                                src='/new/works_page/lavie/Laptop-lavie.png'
                                alt='lavie background image'
                                width={660} 
                                height={400} 
                            />
                        </div>
                    </div>
                </div>

            </section> 


            {/*** Anthemion  ***/}
            <section className={`row py-5 sectionContainer position-relative`}>

                    <div className={`anthemionBgColor`} />

                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center align-items-center">
                            <img 
                            src='/new/works_page/anthemion/deskop_anthemion.png'
                            alt='anthemion background image'
                            width={`959px`}
                            height={`562px`}
                            />
                        </div>
                    </div>


                    <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                        <div className="position_relative">

                            <div className={`anthemion1 d-none d-md-block`}>
                            <img 
                                src='/new/works_page/anthemion/flower-1.png'
                                alt='anthemion flower-1'
                                width={`450px`}
                                height={`456px`}
                            />
                            </div>

                            <div className={`anthemion2`}>
                                <img 
                                    src='/new/works_page/anthemion/flower-2.png'
                                    alt='anthemion flower-2'
                                    width={`450px`}
                                    height={`428px`}
                                />
                            </div>


                            <div className={`bgText`}>
                                <img 
                                    src='/new/works_page/anthemion/anthemion_logo .png'
                                    alt='anthemion logo'
                                    className="img-fluid"
                                />
                                <div>
                                    <p className={`magentoShop`}>Magento Eshop</p>

                                    <p className={`paragraph`}>
                                    The largest online florist network in Greece, with hundreds of collaborations with florists/shops from all around the world.
                                    </p>

                                    <p className={`paragraph`}>
                                    They entrusted us with the construction of their e-shop, and the final result exceeded all expectations!
                                    </p>

                                    <p className={`paragraph`}>
                                    For the construction of the online store anthemionflowers.com, we used the best e-commerce platform, Magento 2. It is one of the most popular e-commerce systems, known for its security, speed, flexibility, and reliability."
                                    </p>

                                    <button data-btn={'anthemion'} className={`btn`}>
                                      
                                      <Link to={'/en/node/112/'}>
                                      See the case study
                                    </Link>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>

            </section>

            
         
            {/*** StyduTours ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>
                <div>
        
                    <img 
                     src="/new/works_page/studytours/studytours_bg.png"
                     alt="study tours bg"
                     style={{
                         objectFit: "cover",
                         height: '70%',
                         position: 'absolute',
                         width: '100%',
                         left: '0',
                         top: '15%',
                         right: '0',
                         bottom: '0',
                         color: 'transparent',

                     }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1  d-flex justify-content-center align-items-center'>

                    <div className="position_relative">

                        <div className={`studytours1`}>
                            <img 
                            src='/new/works_page/studytours/studytours_item.png'
                            alt='studytours aeroplane'
                            width={`400px`}
                            height={`267px`}
                            />
                        </div>


                        <div className={`bgText`}>

                            <img 
                                src='/new/works_page/studytours/studytours_logo.png'
                                alt='studytours logo'
                                className="img-fluid"
                            />

                            <div>
                                <p className={`magentoShop`}>
                                    Drupal WebShop
                                </p>

                                <p className={`paragraph`}>
                                Stydy Tours has been active in the field of educational tourism and travel since 1964, offering comprehensive educational proposals to students, prospective university applicants, and professionals.
                                </p>

                                <p className={`paragraph`}>
                                For this e-shop project, we dove deep into cutting-edge technology to ensure an unparalleled user experience and robust functionality. Leveraging Drupal as our backend, renowned for its powerful content management capabilities, combined with React for the frontend, enabled us to create a dynamic, responsive, and intuitive interface. This fusion of technologies not only elevated the platform's overall performance but also enhanced its adaptability to ever-evolving digital trends.
                                </p>


                                <button data-btn={'studytours'} className={`btn`}>
                                  <Link to={`/en/works/seo-studytours`}>
                                  See the case study
                                  </Link>
  
                                </button>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <div>
                            <img 
                            src='/new/works_page/studytours/studytours_desktop.png'
                            alt='studytours desktop image'
                            width={`959px`}
                            height={`679px`}
                            />
                        </div>
                    </div>
                </div>

            </section>


            {/*** Ahmad Tea ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>

            <div className={`anthemionBgColor`} />

            <div className="col-12 col-lg-6">
                <div className="d-flex justify-content-center align-items-center">
                    

                    <img 
                    src='/new/works_page/ahmadtea/ahmadtea-desktop.png'
                    alt='ahmadtea desktop image'
                    width={`959px`}
                    height={`600px`}
                    />
                </div>
            </div>


            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                <div className="position_relative">

                    <div className={`ahmadtea1`}>
                        <img 
                        src='/new/works_page/ahmadtea/ahmadtea-item-1.png'
                        alt='ahmadtea-item-1'
                        width={`368px`}
                        height={`197px`}
                        />
                    </div>

                    <div className={`ahmadtea2`}>
                       

                        <img 
                          src='/new/works_page/ahmadtea/ahmadtea-item-1.png'
                          alt='ahmadtea-item-1'
                          width={`368px`}
                          height={`197px`}
                        />
                    </div>

                    <div className={`ahmadtea3`}>
                        

                        <img 
                        src='/new/works_page/ahmadtea/ahmadtea-item-2.png'
                        alt='ahmadtea-item-2'
                          width={`400px`}
                        height={`421px`}
                        />
                    </div>


                    <div className={`bgText`}>
                        

                        <img 
                         src='/new/works_page/ahmadtea/Ahmad-Tea-Logo-300px.png'
                         alt='Ahmad-Tea-Logo logo'
                         className="img-fuild"
                        />

                        <div>
                            <p className={`magentoShop`}>Magento Eshop</p>

                            <p className={`paragraph`}>
                                Η εταιρεία Ahmad Tea είναι μια οικογένειακή εποχείρηση με βάση το Ηνωμένο Βασίλειο. Ιδρύθηκε με πάθος για καλύτερο τσάι και έπειτα από τέσσερις γενιές εμπειρίας, συνεχίζει την εξειρετική πορεία της, εξασφαλίζοντας ότι κάθε φλιτζάνι που φέρει το όνομα της ανταποκρίνεται στις υψηλές προσδοκίες των πελατών της.
                            </p>

                            <p className={`paragraph`}>
                                Η Ahmad Tea πλέον ανήκει στις 5 πρώτες εταιρίες τσαγιού με τις περισσότερες πωλήσεις παγκοσμίως, με παρουσία σε πάνω από 80 χώρες. Παράλληλα, συνεχίζει να αναπτύσεται ως μια οικογενειακή επιχείρηση, επεκτείνοντας την φιλανθρωπική της δράση και διατηρώντας σταθερή την υψηλή ποιότητα των προϊόντων της σε όλο τον κόσμο.
                            </p>

                            <button data-btn={'ahmadtea'} className={`btn`}>
                              <Link to={`/en/node/97`}>
                              See the case study
                              </Link>
                              </button>
                        </div>

                    </div>
                </div>

            </div>

            </section>


            {/*** Doctor Anytime  ***/}
            <section className={`row py-5 position-relative mb-5 sectionContainer`}>

                <div>
                 
                    <img 
                     src='/new/works_page/doctor_assistant/doctor_assistant_bg_image.jpg'
                     alt='doctor assistant bg'
                     style={{
                         objectFit: 'cover',
                         height: '70%',
                         transform: 'rotate(180deg)',
                         position: 'absolute',
                         width: '100%',
                         left: '0',
                         top: '15%',
                         right: '0',
                         bottom: '0',
                         color: 'transparent',
                     }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1 d-flex justify-content-center align-items-center'>

                    <div className="position_relative">
                        <div className={`bgText`}>

                          
                            <img 
                             src='/new/works_page/doctor_assistant/logod.png'
                             alt='Ahmad Tea logo'
                             className="img-fuild"
                            />

                            <div>
                                <p className={`magentoShop`}>
                                    Web App
                                </p>

                                <p className={`paragraph`}>
                                    Online Εφαρμογή ηλεκτρονικών ραντεβού, διαχείριση ασθενών, ιατρικός φάκελος και ενσωμάτωση της εφαρμογής στην ιστοσελίδα.
                                </p>

                                <p className={`paragraph`}>
                                    Εύκολο Σύστημα ηλεκτρονικών Ραντεβού, με αυτόματη ενημέρωση της εφαρμογής διαχείρησης για κάθε νέο ραντεβού. Στοιχεία επικοινωνίας ασθενή, ιατρικό ιστρορικό και συνοπτικό ιστορικό των ραντεβού του. Ευέλικτη φόρμα αναζήτησης ασθενών και πλήθος επιπλέον λειτουργιών απαραίτητες για ένα ιατρείο.
                                </p>

                                <button data-btn={'doctor'} className={`btn`}>
         
                                  <Link to={`/en/works/docassistant`}>
                                  See the case study
                                  </Link>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <img 
                        src='/new/works_page/doctor_assistant/doctor_assistant_desktop.png'
                        alt='doctor desktop'
                        width={`959px`}
                        height={`581px`}
                        />
                    </div>
                </div>

            </section>


            {/*** Nosokomeio Rethumnou  ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>
                <div>
            
                    <img 
                      src='/new/works_page/doctor_assistant/doctor_assistant_bg_image.jpg'
                      alt='doctor assistant bg_image'
                      style={{
                          objectFit: 'cover',
                          height: '70%',
                          position: 'absolute',
                          width: '100%',
                          left: '0',
                          top: '15%',
                          right: '0',
                          bottom: '0',
                          color: 'transparent',
                      }}
                    />
                </div>

                <div className="col-12 col-lg-6">
                    <div className="d-flex justify-content-center align-items-center">
                
                    <img 
                     src='/new/works_page/rethumno/rethumno-desktop.png'
                     alt='nosokomeio rethumnou'
                    width={`959px`}
                    height={`581px`}
                    />
                    </div>
                </div>


                <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                <div className="position_relative">
                <div className={`bgText`}>

              

                <img 
                src='/new/works_page/rethumno/rethumno-logo.png'
                alt='nosokomeio rethumnou logo'
                className="img-fluid"
                width={`204px`}
                heigth={`207px`}  
                />

                <div>
                <p className={`magentoShop`}>
                      Wep App
                </p>

                <p className={`paragraph`}>
                Specifically designed for the ophthalmology clinic of Rethymno Hospital, our application manages patients, appointments, and surgeries with exceptional precision and efficiency. Now, complex organizational processes are simplified with just a few clicks.
                </p>

                <p className={`paragraph`}>
                The platform we've developed is not only user-friendly but also meticulously tailored to meet the needs of both patients and medical staff. By leveraging cutting-edge technology, we achieve significant enhancements in care quality, streamlining communication and coordination processes.
                </p>

                <button data-btn={'rethumno'} className={`btn`}> 
                  <Link to={`/en/node/132`}>
                    See the case study
                  </Link>
                  </button>
                </div>

                </div>
                </div>

                </div>

            </section>




        {/*** Contact Form  ***/}
        <section className="ls s-pt-30 s-pt-lg-75 s-pb-130 contact4">
          <div className="container">
            <div className="row c-gutter-60">
              <div className="divider-40 d-none d-xl-block"></div>

              <div className="col-lg-12">
                <form className="contact-form c-mb-20 c-gutter-15">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4>Contact Form</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder name">
                        <label for="name">
                          Full Name
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          aria-required="true"
                          size="30"
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          value={this.state.name}
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="name"
                        />
                      </div>
                      <span style={{ color: "red" }} className="errormessage">
                        {this.state.nameerror}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder email">
                        <label for="email">
                          Email address
                          <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          aria-required="true"
                          size="30"
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          value={this.state.email}
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="email"
                        />
                      </div>
                      <span style={{ color: "red" }} className="errormessage">
                        {this.state.emailerror}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder message">
                        <label for="message">Message</label>
                        <textarea
                          aria-required="true"
                          rows="6"
                          cols="45"
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          value={this.state.message}
                          name="message"
                          id="message"
                          className="form-control"
                          placeholder="message"
                        ></textarea>
                      </div>
                      <span style={{ color: "red" }} className="errormessage">
                        {this.state.messageerror}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group has-placeholder">
                      <button
                        disabled={this.state.buttondisabled}
                        onClick={this.handleSubmit}
                        type="submit"
                        id="contact_form_submit"
                        name="contact_submit"
                        className={"btn btn-block btn-maincolor"}
                      >
                        Send Message
                      </button>
                    </div>
                    <div>
                      <CircleSpinner
                        size={30}
                        color="#686769"
                        loading={this.state.loading}
                      />
                    </div>

                    {this.state.success ? (
                      <h2 style={{ color: "green" }}>
                        Your Message has been Sent
                      </h2>
                    ) : (
                      <h2 style={{ color: "red" }}>{this.state.errorsend}</h2>
                    )}
                  </div>
                </form>
              </div>
              <div className="divider-60 d-none d-xl-block"></div>
            </div>
          </div>
        </section>

        <Footer />
      </Canvas>
    )
  }
}

export default WorksPage

export const query = graphql`
  {
    allSitePlugin(filter: { name: { eq: "gatsby-source-graphql" } }) {
      edges {
        node {
          pluginOptions {
            url
          }
          name
        }
      }
    }
    drupal {
      menuen: menuByName(name: "main", language: EN) {
        links {
          label
          url {
            path
          }
        }
      }

      metainfo: nodeQuery(
        filter: { conditions: [{ field: "title", value: "contact" }] }
      ) {
        entities(language: EN) {
          ... on DRUPAL_NodePage {
            entityLabel
            entityMetatags {
              key
              value
            }
          }
        }
      }
    }
  }
`
